<template>
  <b-card :title="`${$t('General.Add')} ${$t('Social Status')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Save') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import socialStatus from '@/service/administration/social-status'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      name_ar: '',
      name_en: '',
      required,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
        this.switchLoc()
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await socialStatus.postAxios({
        name_ar: this.name_ar,
        name_en: this.name_en,
        user_id: user.id,
      }).then(() => {
        this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.clearForm()
        this.showOverLay = false
      })
    },
    clearForm() {
      this.name_ar = ''
      this.name_en = ''
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
  },
}
</script>
